import React, { FunctionComponent, useEffect, useState, ReactElement, PropsWithChildren } from 'react';
import { useInView } from 'react-intersection-observer';
import { handleKeys } from '../../../helpers/keyboard/keyboard.helper';
import { ButtonStyle, StyledButton, ClickableElement } from '../../buttons';
import { CloseIcon } from '../../svg/icons.component';

import { Overlay } from '../overlay/overlay.component';
import { Portal } from '../portal/portal.component';
import { ZIndices } from '../../../constants/general';
import {
	closeButtonStyle,
	modalHeightStretch,
	modalMaxWidth,
	modalMaxWidthLarge,
	modalMaxWidthNormal,
	modalMaxWidthSmall
} from './modal.css';

export const LOCK_BODY_SCROLL_CLASS_PREFIX = 'lock-body';
const BASE_WRAPPER_CLASSES = 'flex justify-center items-start h-100';

const MODAL_SIZE_MAP = {
	small: `w-100 ${modalMaxWidthSmall}`,
	normal: `w-100 ${modalMaxWidthNormal}`,
	large: `w-100 ${modalMaxWidthLarge}`,
	stretch: `w-100 w-90-ns ${modalHeightStretch} ${modalMaxWidth}`,
	// sized based on its content. Originally intended for video players
	fit: `dib ${modalMaxWidth}`
};

export type ModalSizes = keyof typeof MODAL_SIZE_MAP;

export type ModalAction = {
	title: string;
	automationHook?: string;
	onClick: () => void;
	disabled?: boolean;
	isBusy?: boolean;
	busyText?: string;
	buttonStyle?: Extract<ButtonStyle, 'PRIMARY' | 'SECONDARY' | 'INTERNAL' | 'DANGER'>;
};

export type ModalPositioningProps = {
	shouldScroll: boolean;
	zIndex?: ZIndices;
};

const ModalPositioning: FunctionComponent<PropsWithChildren<ModalPositioningProps>> = ({ children, shouldScroll, zIndex }) => {
	const wrapperClasses = shouldScroll ? `${BASE_WRAPPER_CLASSES} overflow-y-auto` : BASE_WRAPPER_CLASSES;
	return (
		<div className={`fixed z-${zIndex} left-0 top-0 w-100 h-100 w-100`}>
			<div className={wrapperClasses}>{children}</div>
		</div>
	);
};

/**
 * Modal must be passed either a title OR an ariaLabel OR both
 */
export type ModalProps = {
	primaryAction?: ModalAction;
	secondaryActions?: ModalAction[];
	closeAction?: () => void;
	triggerElement?: ReactElement | JSX.Element;
	size?: ModalSizes;
	onOpen?: Function;
	noCloseButton?: boolean;
	modalId?: string;
	leftAlignTitle?: boolean;
	className?: string;
	disableClose?: boolean;
	// If true (default), add padding on the modal body. If false, do not add padding.
	padBody?: boolean;
	isInternal?: boolean;
	zIndex?: ZIndices;
	// whether or not we restore focus after the modal is closed
	restoreFocus?: boolean;
} & (
	| {
			title: string | JSX.Element;
			ariaLabel?: string;
	  }
	| {
			title?: string | JSX.Element;
			ariaLabel: string;
	  }
);

export type ModalTriggerProps = {
	triggerElement: ReactElement | JSX.Element;
	openModal: () => void;
	ariaLabel: string;
	testId?: string;
	className?: string;
};

export const ModalTrigger: FunctionComponent<ModalTriggerProps> = ({ triggerElement, openModal, ariaLabel, testId }) => {
	const hasClickableTrigger = Boolean(
		triggerElement?.type === 'button' ||
			(typeof triggerElement?.type === 'function' && triggerElement?.type?.name === 'StyledButton') ||
			triggerElement?.props?.buttonStyle
	);
	return hasClickableTrigger && triggerElement ? (
		React.cloneElement(triggerElement, { onClick: openModal, 'aria-label': ariaLabel, ...triggerElement.props })
	) : (
		<ClickableElement
			ariaLabel={ariaLabel}
			className="w-inherit"
			onClick={openModal}
			onKeyDown={handleKeys(['Enter', ' '], openModal)}
			data-testid={testId}
			tabIndex={0}>
			{triggerElement}
		</ClickableElement>
	);
};

export const Modal: FunctionComponent<PropsWithChildren<ModalProps>> = ({
	triggerElement,
	title,
	ariaLabel,
	primaryAction,
	secondaryActions = [],
	children,
	closeAction,
	size = 'normal',
	onOpen,
	noCloseButton = false,
	modalId = '',
	leftAlignTitle = false,
	className = '',
	disableClose = false,
	padBody = true,
	zIndex = '5',
	isInternal = false,
	restoreFocus = true
}) => {
	const [showModal, setShowModal] = useState(triggerElement === undefined);
	const [shouldScroll, setShouldScroll] = useState(false);
	const titleValue = typeof title === 'string' ? title : undefined;
	const modalName = titleValue || ariaLabel || 'unknown';
	const portalChildId = `modal-portal-${modalName.replace(/\s+/g, '-').toLowerCase()}`;

	/**
	 * When multiple modals are present on the page, they need to have a unique modalId passed in
	 * to avoid `overflow: hidden` from being removed when another modal is still open
	 */
	const LOCK_BODY_SCROLL_CLASS = `${LOCK_BODY_SCROLL_CLASS_PREFIX}${modalId.replace(/\s+/g, '-').toLowerCase()}`;

	function close() {
		if (!disableClose) {
			setShowModal(false);
		}
		if (closeAction) {
			closeAction();
		}
	}

	const openModal = () => {
		setShowModal(true);
	};

	/**
	 * Do not mount children until modal is mounted and available in the DOM. (Portal component will
	 * have mounted before modal component mounts, so this should solve race condition of the embedded
	 * video component mounting before the modal/portal is available in the DOM.)
	 */
	const [mountChildren, setMountChildren] = useState(false);
	useEffect(() => {
		setMountChildren(true);
	}, []);

	/**
	 * When modal is open we append a overflow-hidden class to the document
	 * body to prevent scrolling.  We also remove the overflow-hidden class when
	 * the modal is unmounted
	 */
	useEffect(() => {
		if (showModal) {
			document.body.classList.add(LOCK_BODY_SCROLL_CLASS);
			if (onOpen) {
				onOpen();
			}
		} else {
			document.body.classList.remove(LOCK_BODY_SCROLL_CLASS);
		}
		return () => document.body.classList.remove(LOCK_BODY_SCROLL_CLASS);
	}, [showModal, onOpen, LOCK_BODY_SCROLL_CLASS]);

	// if the modal is taller that the window then allow it's container to scroll
	const { ref: modalContentRef, entry } = useInView({ threshold: 1 });
	useEffect(() => {
		if (Number(entry?.intersectionRatio) < 1) {
			setShouldScroll(true);
		}
	}, [entry, entry?.intersectionRatio]);

	return (
		<>
			{showModal && (
				<Portal portalRootId="modal-root" portalChildId={portalChildId} restoreFocus={restoreFocus}>
					<ModalPositioning shouldScroll={shouldScroll} zIndex={zIndex}>
						<div
							data-testid="modal"
							className={`${MODAL_SIZE_MAP[size]} z-5 tc pa3 pa0-ns mt6-ns ${className}`}
							role="dialog"
							aria-modal="true"
							aria-label={titleValue || ariaLabel}>
							<div className="overflow-hidden br3 sans-serif lh-copy shadow-3 mb5 h-100 relative">
								{!noCloseButton && (
									<ClickableElement
										ariaLabel="close modal"
										className={`absolute ${closeButtonStyle} pa1 w2 h2 pointer`}
										onClick={close}
										onKeyDown={handleKeys(['Enter', ' '], close)}
										tabIndex={0}>
										<CloseIcon className="w1 h1 pa1 theme-secondary hover-theme-black" />
									</ClickableElement>
								)}
								{title && (
									<div
										className={`pv3 ph5 br3 br--top f4 f3-ns fw6 lh-title tc ${
											isInternal ? 'bg-theme-internal-lighter' : 'bg-theme-grey-lighter'
										}`}
										data-testid="ModalHeader">
										<div className={`tc2-title ${leftAlignTitle ? 'tl' : ''}`}>{title}</div>
									</div>
								)}
								<div
									ref={modalContentRef}
									className={`lh-copy bg-theme-white h-100 ${(title || primaryAction) && padBody ? 'pa4' : ''}`}
									data-testid="ModalContent">
									{mountChildren && children}
								</div>
								{primaryAction && (
									<div
										className={`ph4 pb4 flex flex-column flex-row-ns flex-wrap justify-end bg-theme-white`}
										data-testid="ModalFooter">
										{secondaryActions.map((action, index) => (
											<div key={index} className="ml2-ns mb2 mb0-ns w-100 w-auto-ns">
												<StyledButton
													onClick={action.onClick}
													automationHook={action.automationHook}
													disabled={action.disabled}
													buttonStyle="SECONDARY">
													{action.title}
												</StyledButton>
											</div>
										))}
										<div className="ml2-ns w-100 w-auto-ns">
											<StyledButton
												onClick={primaryAction.onClick}
												automationHook={primaryAction.automationHook}
												disabled={primaryAction.disabled}
												isBusy={primaryAction.isBusy}
												busyText={primaryAction.busyText}
												buttonStyle={primaryAction.buttonStyle}>
												{primaryAction.title}
											</StyledButton>
										</div>
									</div>
								)}
							</div>
						</div>
						<Overlay zIndex={'4'} style="dark" onClick={close}></Overlay>
					</ModalPositioning>
				</Portal>
			)}

			{triggerElement && <ModalTrigger triggerElement={triggerElement} openModal={openModal} ariaLabel={modalName} testId="toggle" />}
		</>
	);
};
